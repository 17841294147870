angular.module('enervexSalesappApp').factory('SystemsManager', function SystemsManager(_, Util) {
	return {
		traverseSystemOutlets:function(v, currentSystem, design) {
			var outlet = _.first(Util.getEndsIn(v, design))
			if (outlet  && !outlet.systemId) {
				this.bindSystem(outlet, currentSystem, design)
				this.traverseSystemInlets(outlet, currentSystem, design)
				this.traverseSystemOutlets(outlet, currentSystem, design)
			}
		},
		traverseSystemInlets: function(v, currentSystem, design) {
			var self = this;
			var inlets = Util.getInlets(v, design)
			_.each(inlets, function(inlet){
				if (!inlet.systemId) {
					self.bindSystem(inlet, currentSystem,design)
					self.traverseSystemInlets(inlet, currentSystem, design)
				}
				return true
			})
		},
		traverseSystems: function(design) {
			var self = this
			if (!design.fc5layout) {
				return
			}
			var originalSystems = design.fc5layout.systems
			design.fc5layout.systems = []
			if (!(design.fc5layout && design.fc5layout.Layout)) {
				return
			}
			var appliances = _.filter(design.fc5layout.Layout, function(v){
				return (v && v.Appl && v.Fit1 == "STP")
			})
			var currentSystem = null
			_.each(design.fc5layout.Layout, function(v) {
				v.systemId = null
				v.GUIidentPath  = null
				if (v.Appl) {
					v.GUIidentPath = null
					v.systemId = null
				}
			})
			_.each(appliances, function(v){
				if (!v.systemId) {
					var newId = design.fc5layout.systems.length + 1
					var currentSystem = _.find(originalSystems, function(s){
						return s.id == newId
					})
					if (!currentSystem) {
						currentSystem = {
							id: newId,
							label: "SYS"+newId
						}
					}
					design.fc5layout.systems.push(currentSystem)
					self.bindSystem(v, currentSystem, design)
					self.traverseSystemOutlets(v, currentSystem, design)
				}
				return true
			})
			self.availableSystems = [{id:"All"}].concat(design.fc5layout.systems)
		},
		availableSystems: [],
		// selectedSystem: "All",
		hasMultipleSystems: function(design) {
			try {
				return design.fc5layout.systems.length > 1
			}catch(e) {
				return false
			}
		},
		bindSystem: function(v, system, design) {
			v.systemId = system.id
			// console.log("set " + v.GUIident + " to system "+system.id)
			v.GUIidentPath = v.systemId + "." + v.GUIident
			if (v.Appl && v.Fit1 == "STP") {
				v.Appl.GUIidentPath = v.systemId + "." + v.Appl.GUIident
			}
		},
		multipleSystemsSupported: function(design) {
			return design.include.stack && ! design.include.exhaust
		},
		canCreateSystem: function(design) {
			return design.application.code == "BOM"
		},
		canRemoveSystems: function(design) {
			return design.application.code == "BOM"
		},
		canRemoveSystem: function(system, design) {
			if (!this.canRemoveSystems(design)){
				return false
			}
			if (this.hasItems(system, design)) {
				return false
			} else if (system.id == 1){
				return false
			} else {
				return true
			}
		},
		hasItems:function(system, design) {
			var item = _.find(design.items, function(item){
				return _.find(item.systemSources, function(systemSource){
					return systemSource.systemId == system.id
				})
			})
			return item ? true : false
		},
		systemName: function(systemId, design) {
			if (systemId == "All") {
				return systemId
			}
			var system = _.find(design.fc5layout.systems, function(s){
				return s.id == systemId
			})
			return system && system.label ? system.label + "["+ system.id+"]" : "SYS"+systemId
		},
		calcCount:function(item, level, systemId, lineitemSources) {
			var source = null
			if (systemId == "All") {
				 source = item.source
			} else {
				source = _.find(item.systemSources, function(ss){
					return ss.systemId == systemId
				})
			}
			var count = 0
			if (!source) {
				console.log("source not found for ", item)
			} else if (level && level != "All") {
				count = source[level]
			} else {
				var codes = _.map(lineitemSources, function(o){
					return o.code
				})
				_.each(codes, function(level){
					count = count + source[level]
				})
			}
			return count
		},
	}
})